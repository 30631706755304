import { useContext, useCallback, useState } from "react";
import { Button, Box, styled } from "@mui/material";

import { BlackjackContext, Card } from "../../../../contexts/BlackjackContext";
import { colors } from '../../../../utils/colors';
import { HitIcon, StandIcon, DoubleDownIcon, SplitIcon } from "./GameButtonIcons";
import { hit, stand, doubleDown, split, insurance } from "../../../../api/blackjack";

const StyledButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  justifyContent: 'center',
  backgroundColor: colors.grey800,
  '&:hover': {
    backgroundColor: colors.freshGrey
  },
  overflow: 'hidden',
  borderRadius: '4px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  textTransform: 'none',
  color: colors.textWhite,
  '&:disabled': {
    opacity: 0.5,
    color: colors.freshWhite,
  },
  fontWeight: 700,
}))

const CustomButton = ({ children, callback, disabled }: { children: React.ReactNode, callback: () => Promise<any>, disabled?: boolean }) => {
  const { gameActive, setGameActive,
    isLoading, setIsLoading,
    setScores,
    updateLastCard,
    dealerHand,
    setCardAndFlip,
    setCurrentHandNumber,
    cardSetTimeout
  } = useContext(BlackjackContext)

  const onClick = useCallback(async () => {
    setIsLoading(true)
    const game = await callback()

    if (game.state.player.length > 1 && (game.state.player[0].value >= 21 || game.state.player[0].actions.includes('stand'))) {
      setCurrentHandNumber(1)
    }

    // open dealer cards if there are more than 1 from backend
    if (!game.active && game.state.dealer[0].cards.length > 1) {
      const secondCard = game.state.dealer[0].cards[1] as Card
      updateLastCard({
        ...secondCard,
        id: dealerHand[0][1].id,
        side: 'front',
        color: ['H', 'D'].includes(secondCard.suit) ? 'red' : 'black'
      }, 'dealer', 0)
      game.state.dealer[0].cards.slice(2).forEach((card: any, index: number) => {
        setTimeout(() => setCardAndFlip(card, 'dealer', 0, true), (index + 1) * cardSetTimeout)
      })
    }

    setScores((prev) => ({ dealer: game.state.dealer.map((hand: any) => hand.value), player: game.state.player.map((hand: any) => hand.value) }))
    if (game.active !== gameActive) setGameActive(game.active)
    setIsLoading(false)
  }, [callback, dealerHand, gameActive, setCardAndFlip, setCurrentHandNumber, setGameActive, setIsLoading, setScores, updateLastCard, cardSetTimeout])

  return (
    <StyledButton
      onClick={onClick}
      disabled={!gameActive || isLoading || disabled}
    >
      {children}
    </StyledButton>
  )
}

const Hit = () => {
  const { currentHandNumber, setCardAndFlip } = useContext(BlackjackContext)
  const callback = async () => {
    const game = await hit()
    setCardAndFlip(game.state.player[currentHandNumber].cards.slice(-1)[0] as Card, 'player', currentHandNumber, true)
    return game
  }

  return (
    <CustomButton
      callback={callback}
    >
      Hit <HitIcon sx={{ fontSize: '14px', ml: '6px', color: colors.yellow }} />
    </CustomButton>
  )
}

const Stand = () => {
  const callback = async () => {
    const game = await stand()

    return game
  }

  return (
    <CustomButton
      callback={callback}
    >
      Stand <StandIcon sx={{ fontSize: '14px', ml: '6px', color: colors.pink }} />
    </CustomButton>
  )
}

const Split = () => {
  const { setCardAndFlip, setPlayerHand, playerHand } = useContext(BlackjackContext)
  const callback = async () => {
    setPlayerHand(prev => {
      return [
        [prev[0][0]],
        [prev[0][1]]
      ]
    })
    const game = await split()

    setCardAndFlip(game.state.player[0].cards[1] as Card, 'player', 0, true)
    setCardAndFlip(game.state.player[1].cards[1] as Card, 'player', 1, true)
    return game
  }
  return (
    <CustomButton
      callback={callback}
      disabled={playerHand[0].length > 1 && (playerHand[0][0].rank !== playerHand[0][1].rank)}
    >
      Split <SplitIcon sx={{ fontSize: '14px', ml: '6px', color: colors.textWhite }} />
    </CustomButton>
  )
}

const DoubleDown = () => {
  const { currentHandNumber, setCardAndFlip } = useContext(BlackjackContext)
  const callback = async () => {
    const game = await doubleDown()
    setCardAndFlip(game.state.player[0].cards.slice(-1)[0] as Card, 'player', currentHandNumber, true)

    return game
  }
  return (
    <CustomButton
      callback={callback}
    >
      Double Down <DoubleDownIcon sx={{ fontSize: '14px', ml: '6px', color: colors.textWhite }} />
    </CustomButton>
  )
}

const Insurance = ({ insurance_, setInsuranceUsed }: { insurance_: boolean, setInsuranceUsed: any }) => {
  const callback = async () => {
    await insurance(insurance_ ? 'insurance' : 'noInsurance')
    setInsuranceUsed(true)
  }
  return (
    <StyledButton
      onClick={callback}
    >
      {insurance_ ? 'Insurance' : 'No Insurance'}
    </StyledButton>
  )

}

const GameButtons = () => {
  // const { dealerHand } = useContext(BlackjackContext)
  // const [insuranceUsed, setInsuranceUsed] = useState(false)
  return (
    <Box sx={{
      display: 'grid',
      gridTemplateColumns: '2fr 2fr',
      gap: '8px',
      width: '100%',
      my: '16px',
    }}>
      {/* {dealerHand[0][0] && dealerHand[0][0].rank === 'A' && !insuranceUsed ?
        <>
          <Insurance insurance_={true} setInsuranceUsed={setInsuranceUsed} />
          <Insurance insurance_={false} setInsuranceUsed={setInsuranceUsed} />
        </>
        :
        <>
          <Hit />
          <Stand />
          <Split />
          <DoubleDown />
        </>
      } */}
      <Hit />
      <Stand />
      <Split />
      <DoubleDown />
    </Box>
  )
}

export default GameButtons