import { Box, useMediaQuery } from '@mui/material';

import PageWrapper from '../../../wrappers/PageWrapper';
import GameField from './GameField/GameField';
import BetMenu from './BetMenu/BetMenu';

import { BlackjackProvider } from '../../../contexts/BlackjackContext';
import { gameContainerStyles } from '../utils';

const BlackjackGame = () => {
  const isTablet = useMediaQuery('(max-width: 768px)');

  return (
    <BlackjackProvider>
      <PageWrapper>
        <Box sx={{...gameContainerStyles, height: '100%', maxHeight: '700px'}}>
          <BetMenu />
          <GameField isTablet={isTablet}/>
        </Box>
      </PageWrapper>
    </BlackjackProvider>
  )
}

export default BlackjackGame;