import { colors } from "../../utils/colors";

export type PastBetDice = {
  status: 'win' | 'lose';
  rollValue: number;
  betId: string;
};

export const textFieldStyles = {
  color: colors.freshWhite,
  width: '100%',
  '& .MuiOutlinedInput-root': {
    paddingRight: {xs: '8px', md: '16px'},
    '& fieldset': {
      border: `1px solid ${colors.borderGrey}`,
      borderRadius: '8px',
    },
    '&:hover fieldset': {
      border: `1px solid ${colors.borderHover}`,
      transition: 'border-color 0.3s ease-in-out',
    },
    '&.Mui-focused fieldset': {
      border: `2px solid ${colors.borderHover}`,
    },
    '& .MuiInputBase-input': {
      color: colors.freshWhite,
      paddingLeft: {xs: '8px', md: '16px'},
    },
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: colors.freshWhite
    },
  },
  '& .MuiOutlinedInput-root.Mui-disabled': {
    '& fieldset': {
      border: `1px solid ${colors.borderGrey}`,
    },
    '& hover fieldset': {
      border: `1px solid ${colors.borderGrey}`,
    }
  },
}

export const gameContainerStyles = {
  backgroundColor: colors.darkGreyPurple,
  display: 'flex',
  maxWidth: '1300px',
  marginX: 'auto',
  gapY: '16px',
  borderRadius: '8px',
  flexDirection: { xs: 'column-reverse', md: 'column-reverse', lg: 'row', xl: 'row' },
  width: '100%'
}

export const gameFieldStyles = {
  display: 'flex',
  flexGrow: 1,
  padding: { xs: '8px 16px', lg: '20px 16px' },
  paddingTop: { md: '48px' },
  position: 'relative',
  flexDirection: 'column',
  width: '100%',
  maxWidth: { xl: '868px' },
  minHeight: { xs: '200px', lg: '500px' },
  justifyContent: { lg: 'space-between' },
}