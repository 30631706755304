import React, { useContext } from "react"
import { Typography } from '@mui/material';

import { parseUnits } from "viem";

import BetMenuWrapper from "../../../../wrappers/BetMenuWrapper";
import InputField from "../../common/InputField";
import Loader from "../../common/Loader";
import BetButton from "../../common/BetButton";
import GameButtons from "./GameButtons";

import httpClient from "../../../../utils/http-client";
import { showToast } from "../../../../utils/toast";
import { colors } from "../../../../utils/colors";
import { TOASTTYPE } from "../../../../utils/config";
import { BlackjackContext, Card } from "../../../../contexts/BlackjackContext";
import { UserDetailsContext } from "../../../../contexts/UserContext";
import { startGame } from "../../../../api/blackjack";

const BetMenu = () => {
  const {
    betAmount, setBetAmount,
    isLoading, setIsLoading,
    gameActive, setGameActive,
    setCardAndFlip, setDealerHand, setPlayerHand,
    setScores, setCurrentHandNumber,
    maxBet, cardSetTimeout
  } = useContext(BlackjackContext);

  const { setFormattedBalance } = useContext(UserDetailsContext)

  const playGame = () => {
    setDealerHand([[], []])
    setPlayerHand([[], []])
    setScores({ player: [0], dealer: [0] })
    setCurrentHandNumber(0)
    setIsLoading(true)

    startGame(betAmount, 'MCOQ')
    .then((game) => {
      setGameActive(game.active)
      const actions = [
        () => setCardAndFlip(game.state.player[0].cards[0] as Card, 'player', 0, true),
        () => setCardAndFlip(game.state.dealer[0].cards[0] as Card, 'dealer', 0, true),
        () => setCardAndFlip(game.state.player[0].cards[1] as Card, 'player', 0, true),
        () => setCardAndFlip(game.state.dealer[0].cards[0] as Card, 'dealer', 0, false),
        () => {setIsLoading(false); setScores({ dealer: [game.state.dealer[0].value], player: [game.state.player[0].value] })}
      ]
      // sequential drawing of cards
      for (let i = 0; i < actions.length; i++) {
        setTimeout(actions[i], i * cardSetTimeout)
      }
    })
    .catch((error) => {
      showToast(TOASTTYPE.error, error.message)
      setIsLoading(false)
    })
  }

  return (
    <BetMenuWrapper>
      <InputField betAmount={betAmount} setBetAmount={setBetAmount} disabled={isLoading || gameActive} maxBet={maxBet}/>
      <GameButtons/>
      <BetButton
        disabled={isLoading || gameActive}
        onClick={playGame}
        sx={{
          '&:disabled': {
            backgroundColor: isLoading ? colors.pink : colors.neutral700,
            color: isLoading ? '#000' : colors.freshWhite
          },
        }}
      >
        <Typography variant="body2" sx={{ fontWeight: 700, opacity: isLoading ? 0 : 1 }}>Bet</Typography>
        {isLoading && <Loader />}
      </BetButton>
    </BetMenuWrapper>
  )
}

export default BetMenu