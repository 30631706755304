/* eslint-disable no-self-assign */
import { useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import '@rainbow-me/rainbowkit/styles.css'

import Sidebar from './Sidebar/Sidebar';
import Header from './Header/Header';

import Main from './Main';
import Profile from './UserPage/Profile';
import Transactions from './UserPage/Transactions';
import Support from './Support/Support';

import KenoGame from './Games/Keno/Keno';
import DiceGame from './Games/Dice/Dice';
import Mines from './Games/Mines/Mines';
import HiloGame from './Games/Hilo/Hilo';
import BlackjackGame from './Games/Blackjack/Blackjack';

import StatsPage from './StatsPage/StatsPage';
import Betstream from './betstream/Betstream';
import BetCardModal from './betstream/BetCardModal';

export default function AppLayout() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  return (
    <Box sx={{ backgroundColor: '#000', display: 'flex' }}>
      <CssBaseline />
      <BrowserRouter>
        <Header handleDrawerToggle={handleDrawerToggle} />
        <Sidebar
          mobileOpen={mobileOpen}
          handleDrawerClose={handleDrawerClose}
          handleDrawerTransitionEnd={handleDrawerTransitionEnd} />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/support" element={<Support />} />
          <Route path="/games/keno" element={<KenoGame />} />
          <Route path="/games/dice" element={<DiceGame />} />
          <Route path="/games/mines" element={<Mines />} />
          <Route path="/games/hilo" element={<HiloGame />} />
          <Route path="/games/blackjack" element={<BlackjackGame />} />
          <Route path="/statistics" element={<StatsPage />} />
          <Route path="/betstream" element={<Betstream />} />
        </Routes>
          <BetCardModal/>
      </BrowserRouter>
    </Box>
  );
}
