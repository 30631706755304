import httpClient from "../utils/http-client";

const GAME_NAME = 'blackjack'

// for demo only
let fakeState = {
  "id": "daf1ba9d-607c-4f6f-9257-ab61296a2e95",
  "active": false,
  "payoutMultiplier": 0,
  "amountMultiplier": 1,
  "amount": 0,
  "payout": 0,
  "updatedAt": "Sun, 01 Sep 2024 10:35:38 GMT",
  "currency": "mcoq",
  "game": "blackjack",
  "user": {
    "id": "23872019-29af-456b-a4d9-ffd982ba1e87",
    "name": "70r7"
  },
  "state": {
    "player": [
      {
        "value": 17,
        "actions": [
          "deal"
        ],
        "cards": [
          {
            "rank": "7",
            "suit": "C"
          },
          {
            "rank": "K",
            "suit": "D"
          }
        ]
      }
    ],
    "dealer": [
      {
        "value": 10,
        "actions": [
          "deal"
        ],
        "cards": [
          {
            "rank": "K",
            "suit": "H"
          }
        ]
      }
    ]
  }
}

// for demo only
const getTotalScore = (cards: any[]): number => {
  let totalScore = 0;
  let acesCount = 0;

  for (const card of cards) {
    if (card.rank === 'J' || card.rank === 'Q' || card.rank === 'K') {
      totalScore += 10;
    } else if (card.rank === 'A') {
      acesCount += 1;
      totalScore += 11;
    } else {
      totalScore += parseInt(card.rank, 10);
    }
  }

  while (totalScore > 21 && acesCount > 0) {
    totalScore -= 10;
    acesCount -= 1;
  }

  return totalScore;
};

// for demo only
const generateCard = () => {
  const ranks = ['2', '3', '4', '5', '6', '7', '8', '9', '10', 'J', 'Q', 'K', 'A'];
  const suits = ['C', 'D', 'H', 'S'];

  const rank = ranks[Math.floor(Math.random() * ranks.length)];
  const suit = suits[Math.floor(Math.random() * suits.length)];

  return { rank, suit };
}

export const activeGame = async () => {
  await new Promise(resolve => setTimeout(resolve, Math.random() * 1000))
  const game = JSON.parse(window.localStorage.getItem(GAME_NAME) || JSON.stringify(fakeState))
  if (game.active) {
    fakeState = game
  }
  return fakeState
}


export const startGame = async (amount: number, currency: string) => {
  await new Promise(resolve => setTimeout(resolve, Math.random() * 1000))
  fakeState.active = true
  fakeState.amount = amount
  fakeState.currency = currency

  fakeState.state.player[0].cards = [generateCard(), generateCard()]
  fakeState.state.player[0].value = getTotalScore(fakeState.state.player[0].cards)
  fakeState.state.player[0].actions = ['deal']

  fakeState.state.dealer[0].cards = [generateCard()]
  fakeState.state.dealer[0].value = getTotalScore(fakeState.state.dealer[0].cards)
  fakeState.state.dealer[0].actions = ['deal']

  if (fakeState.state.player[0].value >= 21) {
    fakeState.active = false
    window.localStorage.removeItem(GAME_NAME)
  } else {
    window.localStorage.setItem(GAME_NAME, JSON.stringify(fakeState))
  }

  return fakeState
}


export const hit = async () => {
  await new Promise(resolve => setTimeout(resolve, Math.random() * 1000))
  let handIndex = 0
  if (fakeState.state.player.length > 1) {
    handIndex = fakeState.state.player[0].actions.includes('stand') || fakeState.state.player[0].value >= 21? 1 : 0
  }
  fakeState.state.player[handIndex].cards.push(generateCard())
  fakeState.state.player[handIndex].actions.push('hit')
  fakeState.state.player[handIndex].value = getTotalScore(fakeState.state.player[0].cards)
  
  if (fakeState.state.player[handIndex].value === 21) {
    fakeState.active = false
    window.localStorage.removeItem(GAME_NAME)
    while (fakeState.state.dealer[0].value < 21) {
      fakeState.state.dealer[0].cards.push(generateCard())
      fakeState.state.dealer[0].value = getTotalScore(fakeState.state.dealer[0].cards)
    }
  }
  if (fakeState.state.player[handIndex].value > 21) {
    if (fakeState.state.player.length > 1 && handIndex === 0) return fakeState
    fakeState.active = false
    window.localStorage.removeItem(GAME_NAME)
  } else {
    window.localStorage.setItem(GAME_NAME, JSON.stringify(fakeState))
  }

  return fakeState
}


export const stand = async () => {
  await new Promise(resolve => setTimeout(resolve, Math.random() * 1000));

  let handIndex = 0;
  if (fakeState.state.player.length > 1) {
    handIndex = fakeState.state.player[0].actions.includes('stand') || fakeState.state.player[0].value >= 21 ? 1 : 0;
  }

  fakeState.state.player[handIndex].actions.push('stand');

  // Проверяем, активна ли вторая рука
  if (handIndex === 0 && fakeState.state.player.length > 1) {
    return fakeState;
  }

  fakeState.active = false;

  while (fakeState.state.dealer[0].value < 17) {
    fakeState.state.dealer[0].cards.push(generateCard());
    fakeState.state.dealer[0].value = getTotalScore(fakeState.state.dealer[0].cards);
  }

  window.localStorage.removeItem(GAME_NAME);
  return fakeState;
};


export const doubleDown = async () => {
  await new Promise(resolve => setTimeout(resolve, Math.random() * 1000));

  let handIndex = 0;
  if (fakeState.state.player.length > 1) {
    handIndex = fakeState.state.player[0].actions.includes('stand') || fakeState.state.player[0].value >= 21 ? 1 : 0;
  }
  
  fakeState.state.player[handIndex].actions.push('stand'); // stand=doubleDown for easiness
  fakeState.state.player[handIndex].cards.push(generateCard());
  fakeState.state.player[handIndex].value = getTotalScore(fakeState.state.player[handIndex].cards);
  
  fakeState.amount *= 2;
  
  console.log(handIndex, fakeState)
  // Проверяем, активна ли вторая рука
  if (handIndex === 0 && fakeState.state.player.length > 1 && !fakeState.state.player[1].actions.includes('stand')) {
    return fakeState;
  }

  fakeState.active = false;

  while (fakeState.state.dealer[0].value < 17) {
    fakeState.state.dealer[0].cards.push(generateCard());
    fakeState.state.dealer[0].value = getTotalScore(fakeState.state.dealer[0].cards);
  }

  window.localStorage.removeItem(GAME_NAME);
  return fakeState;
};

export const split = async () => {
  await new Promise(resolve => setTimeout(resolve, Math.random() * 1000));

  const firstCard = fakeState.state.player[0].cards[0];

  fakeState.state.player[0].cards = [{ ...firstCard }];
  fakeState.state.player[0].actions.push('split');

  const secondHand = {
    actions: [...fakeState.state.player[0].actions],
    cards: [{ ...firstCard }],
    value: 0
  };

  fakeState.state.player.push(secondHand);

  fakeState.state.player[0].cards.push(generateCard());
  fakeState.state.player[1].cards.push(generateCard());

  fakeState.state.player[0].value = getTotalScore(fakeState.state.player[0].cards);
  fakeState.state.player[1].value = getTotalScore(fakeState.state.player[1].cards);

  window.localStorage.setItem(GAME_NAME, JSON.stringify(fakeState));

  return fakeState;
}

export const insurance = async (action: 'insurance' | 'noInsurance') => {
  await new Promise(resolve => setTimeout(resolve, Math.random() * 1000));
  fakeState.amount /= 2;
  fakeState.state.player[0].actions.push(action);
  window.localStorage.setItem(GAME_NAME, JSON.stringify(fakeState));
  return fakeState;
}