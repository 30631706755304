import React, { useState, createContext, ReactNode, useCallback } from 'react';
import { CardProps } from '../components/Games/common/GameCard';

export type Card = {
  rank: '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | 'J' | 'Q' | 'K' | 'A';
  suit: 'C' | 'D' | 'H' | 'S' | CardProps['suit'];
  side: 'front' | 'back';
  id?: string
  color?: 'red' | 'black';
}

interface BlackjackContextProps {
  betAmount: number;
  setBetAmount: React.Dispatch<React.SetStateAction<number>>;
  dealerHand: Card[][];
  setDealerHand: React.Dispatch<React.SetStateAction<Card[][]>>;
  playerHand: Card[][];
  setPlayerHand: React.Dispatch<React.SetStateAction<Card[][]>>;
  currentHandNumber: number;
  setCurrentHandNumber: React.Dispatch<React.SetStateAction<number>>
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  maxBet: number;
  gameActive: boolean;
  setGameActive: React.Dispatch<React.SetStateAction<boolean>>
  setCardAndFlip: (card: Card, handType: 'player' | 'dealer', handNumber?: number, flip?: boolean) => void
  scores: { player: number[], dealer: number[] }
  setScores: React.Dispatch<React.SetStateAction<{ player: number[], dealer: number[] }>>
  updateLastCard: (card: Card, handType: 'player' | 'dealer', handNumber?: number) => void,
  cardSetTimeout: number
}

export const BlackjackContext = createContext<BlackjackContextProps>({
  betAmount: 0,
  setBetAmount: () => { },
  dealerHand: [],
  setDealerHand: () => { },
  playerHand: [],
  setPlayerHand: () => { },
  currentHandNumber: 0,
  setCurrentHandNumber: () => { },
  isLoading: false,
  setIsLoading: () => { },
  maxBet: 150,
  gameActive: false,
  setGameActive: () => { },
  setCardAndFlip: () => { },
  scores: { player: [0], dealer: [0] },
  setScores: () => { },
  updateLastCard: () => { },
  cardSetTimeout: 400
});

export const BlackjackProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [betAmount, setBetAmount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dealerHand, setDealerHand] = useState<Card[][]>([[], []]);
  const [playerHand, setPlayerHand] = useState<Card[][]>([[], []]);
  const [currentHandNumber, setCurrentHandNumber] = useState<number>(0);
  const [maxBet, setMaxBet] = useState<number>(150);
  const [gameActive, setGameActive] = useState<boolean>(false);
  const [scores, setScores] = useState<{ player: number[], dealer: number[] }>({ player: [0], dealer: [0] });

  const animationTimout = 300;
  const cardSetTimeout = 400;

  const updateLastCard = useCallback((card: Card, handType: 'player' | 'dealer', handNumber: number = 0, push: boolean = false) => {
    const updateHand = handType === 'player' ? setPlayerHand : setDealerHand;

    updateHand((prevHands) => {
      const newHand = [...prevHands[handNumber]];
      
      if (newHand.length > 0 && !push) {
        newHand[newHand.length - 1] = {...card}
      } else {
        newHand.push(card)
      }
      return handNumber === 0? [newHand, prevHands[1]] : [prevHands[0], newHand];
    });
  }, [])

  const setCardAndFlip = useCallback(
    (card: Card, handType: 'player' | 'dealer', handNumber: number = 0, flip: boolean = true) => {
      const newCard = {
        ...card,
        id: `blackjack-card-${handType}-${Math.random().toString(36)}`,
        color: card.suit === 'D' || card.suit === 'H' ? 'red' : 'black',
        side: 'back'
      } as Card;

      updateLastCard(newCard, handType, handNumber, true);
      
      if (!flip) return
      setTimeout(() => {
        updateLastCard({...newCard, side: 'front'}, handType, handNumber, false);
      }, animationTimout);
    },
    [updateLastCard]
  );

  return (
    <BlackjackContext.Provider value={{
      betAmount, setBetAmount,
      isLoading, setIsLoading,
      dealerHand, setDealerHand,
      playerHand, setPlayerHand,
      currentHandNumber, setCurrentHandNumber,
      maxBet, setCardAndFlip,
      gameActive, setGameActive,
      scores, setScores,
      updateLastCard, cardSetTimeout
    }}>
      {children}
    </BlackjackContext.Provider>
  );
};
